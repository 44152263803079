@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

.about ul.list {
  list-style-type: disc;
  list-style-position: inside;
  margin: 0 0 2em;
  color: $primaryColor;
}

.about h3 {
  text-align: center;
  margin: 2em 0;
}

.about p a {font-weight: bold;}
