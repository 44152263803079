@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

.detail .workImage, .detail .slideImage {
  width: 100%;
  margin:  2em auto;
}  

.detail h3 {
  margin: 2em 0 0em;
  text-align: center;
}

.projDesc h4 {
  margin: 1em 0 0em;
}

.projDesc p {
  margin: 0.5em 0 0.5em;
}

@media (max-width: 768px) {  
  .projDesc {
    padding: 0 1em;
  }
  .detail h3 {
    margin: 1em 0;
    text-align: center;
  }
  .projDesc h4 {
    margin: 0 0;
  }
  .projDesc p {
    margin: 0em 0 2em;
  }
	.embedTableau {
		height: 100%;
		min-height: 640px;
		border: 5px solid pink;
	}
}

@media screen and (max-width:320px) {  
  .detail .workImage {
    min-width: auto;
  }  
  .projDesc p {
    margin: 0em 0 2em;
  }
}
