@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

/* header */
.header {
  top: 0;
  width: 100%;
  padding: 1em 0;
  position: fixed;
  flex-direction: row;
  @include flexCenter();
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.6);
	border-bottom: 1px solid rgba($shadowTint,0.4);
  box-shadow: 0 1px 10px $shadowTint;
  z-index: 100;
}

.header ul {
  margin: 0;
  padding: 0 1em 0 0;
  list-style: none;
  flex-direction: row;
  overflow: hidden;
  @include flexCenter();
}

.header li .navItem {
  padding: 0em 0 0 2em;
  @include fontSource($weight: bold);
}

.header li a.navItem:hover {
  color: $primaryColor;
}

.header .logo  {
  padding: 0 0 0 2em;
}

@media (max-width: 768px) {
  header ul {
    margin: 0 1em 0 0;
  }

  .header li .navItem {
    padding: 1em 0 0 1em;
  }

  .header .logo  {
    padding: 0 0 0 1em;
  }

  .header .logo .logoImg { width: 100%; }
}

@media (max-width: 375px) {
  .header ul {
    margin: 0 0.25em 0 0;
  }

  .header li .navItem {
    padding: 0.25em 0 0 0.5em;
  }

  .header .logo  {
    padding: 0.25em 0 0 0.25em;
  }

} 