@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

.hero {
	position: relative;
	top: 0;
	width: 100%;
	height: 100%;
}

// .hero h1 {
//   font-size: 2.8rem;
// 	font-weight: 500;
// 	letter-spacing: 0.155rem;
// }

.MainTitle {
	position: absolute;
	top: 55%;
	left: 5.8rem;
}

.heroImg {
  width: 100%;
  height: auto;
  min-width: 321px;
}

@media (max-width: 742px) {
  .heroImg {
    padding-top: 4.25em;
  }
}

@media (max-width: 375px) {
  .heroImg {
    padding-top: 4.5em;
  }
}