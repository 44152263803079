@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

.heroHome {
	position: relative;
	top: 0;
	width: 100%;
	height: 100%;
}

.mainTitle {
	position: absolute;
	top: 9.5rem;
	left: 5.8rem;
}

.heroHome h1 {
  font-size: 2.8rem;
	font-size: clamp(1.25rem, 4vw, 3.05rem);
	font-weight: 500;
	letter-spacing: 0.155rem;
}

.bold {
	font-size: 4.1rem;
	// font-size: clamp(1.56rem, 2.5vw, 4.1rem);
	font-weight: 400;
}

.heroHome h5 {
	font-size: clamp(0.65rem, 2.5vw, 2.44rem);
	margin-top: 8px;
}


.heroImg {
  width: 100%;
  height: auto;
  min-width: 321px;
}

@media (max-width: 742px) {
	// .mainTitle {
	// 	position: absolute;
	// 	top: 9rem;
	// 	left: 3rem;
	// }

	// // .heroHome h1 {
	// // 	font-size: 1.8rem;
	// // 	font-weight: 500;
	// // 	letter-spacing: 0;
	// // }
	
	// .bold {
	// 	font-size: 2.5rem;
	// 	font-weight: 400;
	// }

	// .heroHome h5 {
	// 	margin-top: 4px;
	// 	font-size: 0.7rem;
	// }

  .heroImg {
    padding-top: 4.25em;
  }
}

@media (max-width: 375px) {
	.mainTitle {
		position: absolute;
		top: 3.5rem;
		left: 5.8rem;
	}

  .heroImg {
    padding-top: 4.5em;
  }
}