@mixin fontFace () {
	font-family: "Encode Sans Semi Condensed", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
	src: url('https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Kaisei+Tokumin&display=swap'),
}

@mixin flexCenter () {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin thumbTextLink () {
  text-align: left;
  text-decoration: none;
  // font-size: 100%;
}

@mixin fontSource ($size: false, $color: false, $weight: false, $lh: false) {
  @include fontFace();
  @if $size { font-size: $size; }
  @if $color { color: $color; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
}

@mixin hr () {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid #ccc; 
}

@mixin autoSpacing () {
  margin: 0 auto;
}

@mixin headerShadow ($bg: $shadowTint, $box-shadow: false) {
  background: $shadowTint;
  box-shadow: 0 2px 12px $shadowDark;
}