/* COLOR */
$primaryColor: #001845;
$primaryColorTint: #1B353B50;
$primaryColorTintContent: #001845;

$seondlyColor: #5C677D;
$seondlyColorTint: #5C677D50;
$seondlyColorTintContent: #5C677D;

$foregroundContentColor: #fff;
$foregroundDarkContentColor: #1F2B2D;

$link: #0088b9;
$linkHover: #03b4cf;

// Background Color
$bgColor: #fafafa;

$backgroundTint: #ffffff88;

// Form
$inputBorder: #979DAC;
$inputBg: hsl(223, 11%, 30%);
$inputFocus: #1F2B2D;

// Shadow
$shadowTint: #e0e0e0;
$shadowDark: #a1a1a1;
$shadowDarker: #757575;
