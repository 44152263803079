@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,html{margin:0;padding:0;box-sizing:border-box;font-size:16px}*,*:before,*:after{box-sizing:inherit}body{background-color:#fafafa;font-family:"Encode Sans Semi Condensed", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;src:url("https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Kaisei+Tokumin&display=swap");line-height:1.6;letter-spacing:normal}.App{width:100%;font-size:100%}.Show{display:block}.Hide{display:none}Body{line-height:1.65}p{font-size:1em}h1,h2,h3,h4,h5{font-family:"Encode Sans Semi Condensed", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;src:url("https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Kaisei+Tokumin&display=swap");font-weight:true;line-height:1.15}h1,.h1{font-size:3.05rem}h2,.h2{font-size:2.44rem}h3,.h3{font-size:1.95rem}h4,.h4{font-size:1.56rem;font-weight:500}h5,.h5{font-size:1.25rem;font-weight:400}body,p{font-size:1rem;font-weight:300}button,.button{font-size:1rem}caption,.caption,.overline{font-size:0.8rem}s small,.text-small{font-size:0.64rem}a{color:#0088b9;text-decoration:none}a:hover{color:#03b4cf}.row{max-width:992px;margin:0 auto}@media (max-width: 320px){.row{margin:0;min-width:321px}}

.Home_heroHome__2EaS5{position:relative;top:0;width:100%;height:100%}.Home_mainTitle__om0cV{position:absolute;top:9.5rem;left:5.8rem}.Home_heroHome__2EaS5 h1{font-size:2.8rem;font-size:clamp(1.25rem, 4vw, 3.05rem);font-weight:500;letter-spacing:0.155rem}.Home_bold__Nuurj{font-size:4.1rem;font-weight:400}.Home_heroHome__2EaS5 h5{font-size:clamp(0.65rem, 2.5vw, 2.44rem);margin-top:8px}.Home_heroImg__18Zmz{width:100%;height:auto;min-width:321px}@media (max-width: 742px){.Home_heroImg__18Zmz{padding-top:4.25em}}@media (max-width: 375px){.Home_mainTitle__om0cV{position:absolute;top:3.5rem;left:5.8rem}.Home_heroImg__18Zmz{padding-top:4.5em}}

.hero{position:relative;top:0;width:100%;height:100%}.MainTitle{position:absolute;top:55%;left:5.8rem}.heroImg{width:100%;height:auto;min-width:321px}@media (max-width: 742px){.heroImg{padding-top:4.25em}}@media (max-width: 375px){.heroImg{padding-top:4.5em}}

.ThumbLayout_container__3Ub8g{display:flex;flex-wrap:wrap;justify-content:space-between;margin:2em auto 0 auto}.ThumbLayout_col__10b2G{margin:0 0 2em;box-shadow:1px 1px 3px 0 #e0e0e0;background:#fff;border-radius:4px;min-height:150px}.ThumbLayout_col__10b2G a{text-align:left;text-decoration:none;color:#5C677D}.ThumbLayout_col__10b2G:hover{background:#fff;height:auto;color:#001845;transform:scale(1.09) translate3d(0, 0, 0);border-radius:3px;box-shadow:3px 2px 5px 3px rgba(224,224,224,0.6);transition:.5s;transition-timing-function:ease}.ThumbLayout_thumbnailImage__Ocifb{width:100%;margin:0 0 0 0;border-radius:4px 4px 0 0}.ThumbLayout_thumblinkImg__3nYxr{text-decoration:none;clear:both}.ThumbLayout_thumbBox__2po9p{padding:1rem 1.6rem}.ThumbLayout_thumbBox__2po9p p{margin:0.6rem 0 0 0;line-height:1.25rem}@media (max-width: 1640px){.ThumbLayout_container__3Ub8g{max-width:1354px}.ThumbLayout_col__10b2G{width:430px}}@media (max-width: 1354px){.ThumbLayout_container__3Ub8g{max-width:994px}.ThumbLayout_col__10b2G{width:310px}}@media (max-width: 1024px){.ThumbLayout_container__3Ub8g{max-width:742px}.ThumbLayout_col__10b2G{width:226px;border-radius:0px}.ThumbLayout_thumbnailImage__Ocifb{width:100%;margin:0 0 0 0;border-radius:0 0 0 0}}@media (max-width: 742px){.ThumbLayout_container__3Ub8g{min-width:320px;margin:0 auto}.ThumbLayout_container__3Ub8g:first{padding-top:none}.ThumbLayout_col__10b2G{width:100%;margin:2em auto 2em auto}.ThumbLayout_col__10b2G:hover{transform:none;border-radius:3px;box-shadow:none;transition:none}}


.UX_main__1Ywxm{display:flex;flex-direction:column;justify-content:center}

.banner{width:100%;display:flex;justify-content:center;align-items:center;margin:6em 0 0 0}.bannerImg{max-width:100%;object-fit:cover;height:auto}@media (max-width: 768px){.banner{margin:0em 0 0 0}}@media screen and (max-width: 320px){.bannerImg{min-width:321px;margin:0 auto}.banner{margin:1em 0 0 0}}

dl,dt,dd{margin:0;padding:0 0 0rem;line-height:1.5rem}ul,dl{list-style:none;padding:left}dt{font-weight:bold;margin:1.5em 0 0 0}dd{white-space:pre-wrap;margin:0 0 1em 0}p{padding:0 0 1em}.bold{font-weight:600;color:#5C677D}.overview h3{text-align:center;padding:1em 0 1em 0}.overview h4{padding:1em 0 0.4em 0}@media (max-width: 768px){.overview{padding:0 1em}p,dt{margin:0 0 0 0}}@media screen and (max-width: 320px){.p,dt{margin:0 0 0 0}}

.detail .workImage,.detail .slideImage{width:100%;margin:2em auto}.detail h3{margin:2em 0 0em;text-align:center}.projDesc h4{margin:1em 0 0em}.projDesc p{margin:0.5em 0 0.5em}@media (max-width: 768px){.projDesc{padding:0 1em}.detail h3{margin:1em 0;text-align:center}.projDesc h4{margin:0 0}.projDesc p{margin:0em 0 2em}.embedTableau{height:100%;min-height:640px;border:5px solid pink}}@media screen and (max-width: 320px){.detail .workImage{min-width:auto}.projDesc p{margin:0em 0 2em}}

.imageSlides{width:100%;position:relative;display:flex;justify-content:center;align-items:center;-webkit-animation:slideshow 10s linear infinite;animation:slideshow 10s linear infinite}.navContainer{width:100%;margin:0;display:flex;justify-content:center;align-items:center;justify-content:space-between;position:absolute;transition:opacity 300ms linear}.slideImage{width:100%;margin:0;transition:opacity 1.2s}.slideImage:active{transition:opacity 1.2s}.dotContainer{width:135px;margin:0 auto;display:flex;justify-content:center;align-items:center;justify-content:space-evenly;position:absolute;bottom:2.5em}.dot{width:1em;height:1em;border-radius:50%;background:#fff;margin:0 10px;cursor:pointer;transition:0.2s ease}.prev,.next{font-size:1.5em;padding:1em;color:rgba(255,255,255,0.533333);border-radius:0 5px 5px 0;cursor:pointer;transition:background-color 0.2s ease}.next{border-radius:5px 0 0 5px}.prev:hover,.next:hover{background-color:rgba(255,255,255,0.533333)}.fade{-webkit-animation-name:fade;animation-name:fade;-webkit-animation-duration:1.5s;animation-duration:1.5s}@-webkit-keyframes fade{from{opacity:.4}to{opacity:1}}@keyframes fade{from{opacity:.4}to{opacity:1}}@media (max-width: 768px){.dot{width:0.5em;height:0.5em;border-radius:50%;background:#fff;margin:0 10px;cursor:pointer;transition:0.2s ease}}

.graphics h3{margin:0em 0 1em;text-align:center}

.row{padding:0 1em}

.about ul.list{list-style-type:disc;list-style-position:inside;margin:0 0 2em;color:#001845}.about h3{text-align:center;margin:2em 0}.about p a{font-weight:bold}

.header{top:0;width:100%;padding:1em 0;position:fixed;flex-direction:row;display:flex;justify-content:center;align-items:center;justify-content:space-between;background:rgba(255,255,255,0.6);border-bottom:1px solid rgba(224,224,224,0.4);box-shadow:0 1px 10px #e0e0e0;z-index:100}.header ul{margin:0;padding:0 1em 0 0;list-style:none;flex-direction:row;overflow:hidden;display:flex;justify-content:center;align-items:center}.header li .navItem{padding:0em 0 0 2em;font-family:"Encode Sans Semi Condensed", 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;src:url("https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Kaisei+Tokumin&display=swap");font-weight:bold}.header li a.navItem:hover{color:#001845}.header .logo{padding:0 0 0 2em}@media (max-width: 768px){header ul{margin:0 1em 0 0}.header li .navItem{padding:1em 0 0 1em}.header .logo{padding:0 0 0 1em}.header .logo .logoImg{width:100%}}@media (max-width: 375px){.header ul{margin:0 0.25em 0 0}.header li .navItem{padding:0.25em 0 0 0.5em}.header .logo{padding:0.25em 0 0 0.25em}}

.contact{margin:32px auto 0;text-align:center}.contact p{margin:2em 0 0 0}

