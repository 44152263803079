@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2em auto 0 auto;
}

.col {
  margin: 0 0 2em;
  box-shadow: 1px 1px 3px 0 $shadowTint;
  background: $foregroundContentColor;
  border-radius: 4px;
  min-height: 150px;
}

.col a {
  @include thumbTextLink();
  color: $seondlyColor;
}

.col:hover {
  background: $foregroundContentColor;
  height: auto;
  color: $primaryColor;
  transform: scale(1.09) translate3d( 0, 0, 0);
  border-radius: 3px;
  box-shadow: 3px 2px 5px 3px rgba($shadowTint, 0.6);
  transition: .5s;
	transition-timing-function: ease;
}

// .box {
//   margin: 0 0 1em 0; 
// 	box-shadow: 3px 3px 15px 0 $shadowTint;
// 	border: pink;
// }

.thumbnailImage {
  width: 100%;
  margin: 0 0 0 0;
  border-radius: 4px 4px 0 0;
}

.thumblinkImg {
  text-decoration: none;
  clear: both;
}

.thumbBox {
  padding: 1rem 1.6rem;
}

.thumbBox p {
  margin: 0.6rem 0 0 0;
	line-height: 1.25rem;
}

 @media (max-width: 1640px) {
  .container {
    max-width: 1354px;
    }
    .col {
      width: 430px;
   }
 }
 @media (max-width: 1354px) {
  .container {
    max-width: 994px;
    }
    .col {
      width: 310px;
    }
}
@media (max-width: 1024px) {
  .container {
    max-width: 742px;
  }
  .col {
    width: 226px;
    border-radius: 0px;
  }
  .thumbnailImage {
    width: 100%;
    margin: 0 0 0 0;
    border-radius: 0 0 0 0;
  }
}

@media (max-width: 742px) {
  .container {
    min-width: 320px;
    margin: 0 auto;
    // padding-top: 2em;
  }
	.container:first {
		padding-top: none;
	}
  .col {
    width: 100%;
    margin:  2em auto 2em auto;
  }
  .col:hover {
    transform: none;
    border-radius: 3px;
    box-shadow: none;
    transition: none;
  }
}