@import './constants/Colors.scss';
@import './constants/Mixin.scss';

// @import url(https://fonts.googleapis.com/css?family=Sarabun); 
@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap); 

*, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after { box-sizing: inherit; }

body {
  background-color: $bgColor;
  @include fontSource ($size: false, $color: false, $weight: false, $lh: 1.6);
	letter-spacing: normal;
}

.App {
  width: 100%;
  font-size: 100%;
}

.Show{
  display:block;
}

.Hide{
  display:none;
}

/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
Body {
	line-height: 1.65;
}

p {
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5 {
  @include fontSource ();
  font-weight: true;
  line-height: 1.15;
}

h1, .h1 {
  font-size: 3.05rem;
}

h2, .h2 {
  font-size: 2.44rem; 
}

h3, .h3 {
  font-size: 1.95rem; 
}

h4, .h4 {
  font-size: 1.56rem;
	font-weight: 500;
}

h5, .h5 {
  font-size: 1.25rem; 
	font-weight: 400;
}

body, p {
  font-size: 1rem;
	font-weight: 300;
}

button, .button{
  font-size: 1rem; 
}

caption, .caption, .overline  {
  font-size: 0.8rem; 
}
s
small,
.text-small {
  font-size: 0.64rem;
}

a {
  color: $link;
  text-decoration: none;
}

a:hover {
  color: $linkHover;
}

.row {
	max-width: 992px;
  margin: 0 auto;
} 

// @media (max-width: 468px) {
//   .row { margin: 0; }
//   h3, .h3 {
//     font-size: 1rem; /* 32px */
//     line-height: 	2rem;
//   }
  
//   h4, .h4 {
//     font-size: 1rem; /* 16px */
//     line-height: 1.25rem;
//   }
// }

@media (max-width: 320px) {
  .row { 
    margin: 0;
    min-width: 321px;
   }
}

