@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

.banner {
    width: 100%;
    @include flexCenter();
    margin: 6em 0 0 0;
  }
  .bannerImg {
    max-width: 100%;
		object-fit: cover;
    height: auto;
  }

 @media (max-width: 768px) {
    .banner {
      margin: 0em 0 0 0;
  }
}
@media screen and (max-width:320px) {
  .bannerImg {
    min-width: 321px;
    margin: 0 auto;
  }
    .banner {
      margin: 1em 0 0 0;
    } 
}
