@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

.imageSlides {
  width: 100%;
  position: relative;
  @include flexCenter();
  animation: slideshow 10s linear infinite;
}

.navContainer {
  width: 100%;
  margin: 0;
  @include flexCenter();
  justify-content: space-between;
  position: absolute;
  transition: opacity 300ms linear;
}

.slideImage{
  width: 100%;
  margin: 0;
  transition: opacity 1.2s;
}

.slideImage:active {transition: opacity 1.2s;}

.dotContainer {
 width: 135px;
 margin: 0 auto;
 @include flexCenter();
 justify-content: space-evenly;
 position: absolute;
 bottom: 2.5em;
}

.dot {
  width: 1em;
  height: 1em;
  border-radius:50%;
  background: $foregroundContentColor;
  margin: 0 10px;
  cursor: pointer;
  transition: 0.2s ease;
}

.prev, .next {
  font-size: 1.5em;
  padding: 1em;
  color: $backgroundTint;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition:background-color 0.2s ease;
}

.next {
  border-radius: 5px 0 0 5px;
}

.prev:hover, .next:hover {
  background-color: $backgroundTint;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}
@media (max-width: 768px) {  
  .dot {
    width: 0.5em;
    height: 0.5em;
    border-radius:50%;
    background: $foregroundContentColor;
    margin: 0 10px;
    cursor: pointer;
    transition: 0.2s ease;
  }
}