@import '../../constants/Colors.scss';
@import '../../constants/Mixin.scss';

dl, dt, dd {
  margin: 0;
  padding: 0 0 0rem;
  line-height: 1.5rem;
}

ul, dl {
  list-style: none;
  padding: left;
}

dt { 
  font-weight: bold; 
  margin: 1.5em 0 0 0;
}
dd {
  white-space: pre-wrap;
  margin: 0 0 1em 0;
}
p {
  padding: 0 0 1em; 
}
.bold {
	font-weight: 600;
	color:$seondlyColor;
}

.overview h3 {
  text-align: center;
  padding: 1em 0 1em 0;
}

.overview h4 {
  padding: 1em 0 0.4em 0;
}

@media (max-width: 768px) {
  .overview {
    padding: 0 1em;
  }
  p, dt {
    margin: 0 0 0 0;
  } 
}

@media screen and (max-width:320px) {
  .p, dt {
    margin: 0 0 0 0;
  } 
}
